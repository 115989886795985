class validation{

    constructor(type, e, inputid){

        this.validationType = this.setValidationType(type);
        this.disabled = true;
        this.isValidName = false;
        this.isValidInputEmail = false;
        this.errorMessageCondtional = {
            inputdisplay: {
                generateerror: false,
                errormessageexist: false
            }
        };
        this.element = e;
        this.inputid = inputid;

    }

    setValidationType(type){

        return type;

    }

    getValidationType(){

        return this.validationType;

    }

    validationStructure(){

        //Case Types
        //First Case for Letters, Spaces and Numbers
        switch(this.validationType){

            case 1:

                this.onlyLettersAndSpacesAndNumbers(this.element, this.inputid);

            break;

            case 2:

                this.isValidEmail(this.element, this.inputid);

            break;

            case 3:

                this.isValidUrl(string, idname);

            break;

        }


    }

    isValidEmail(e, inputid){

        //Get Element
        let element = e.currentTarget;

        ///Get Span Error Message Element
        let errorElement = element.nextElementSibling;

        //Get Value From Input
        let value = element.value;

        //Verify Value
        if(value){

            let emailRegex = new RegExp(/^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/, "gm");

            this.isValidInputEmail = emailRegex.test(value);

            //Show Message Error
            if(!this.isValidInputEmail && value){

                //InputName Verify
                this.errorMessageCondtional.inputdisplay.generateerror = true;

                //Verify conditional
                if(this.errorMessageCondtional.inputdisplay.generateerror){

                    //Show Error Message
                    if(!this.errorMessageCondtional.inputdisplay.errormessageexist){

                        this.createErrorMessageElement(inputid, 'Please use a valide Email');
                        element.classList.add('inputerror');
                        this.disabled = true;

                    }

                    //Verify Error Message Exist
                    this.errorMessageCondtional.inputdisplay.errormessageexist = true;

                    //Disabled Button
                    this.disabled = true;

                    return this.disabled;

                }

            }else{

                //InputName Verify
                this.errorMessageCondtional.inputdisplay.generateerror = false;

                //Verify Error Message Exist
                this.errorMessageCondtional.inputdisplay.errormessageexist = false;

                //Set Original Value for variable
                this.isValidInputEmail = false;

                //Enabled Button
                this.disabled = false;

                //Remove Message
                if(errorElement){

                    if(errorElement.classList.contains('errorMessage') && !this.errorMessageCondtional.inputdisplay.generateerror){

                        errorElement.remove();
                        element.classList.remove('inputerror');
                    }

                }

                return this.disabled;

            }

        }else{

            //InputName Verify
            this.errorMessageCondtional.inputdisplay.generateerror = false;

            //Verify Error Message Exist
            this.errorMessageCondtional.inputdisplay.errormessageexist = false;

            //Remove Message
            if(errorElement){

                if(errorElement.classList.contains('errorMessage')){

                    errorElement.remove();
                }

            }

        }

        return this.disabled;

    }

    validNameCases(type, value){

        switch(type)
        {

            //Only Letters and special char
            case 1:
                this.isValidName = /^[A-Z-a-z-À-ü( )\ç\s]+$/gm.test(value);
            break;

            //Only Numbers
            case 2:
                this.isValidName = /^[0-9( )\s\-]+$/gm.test(value);
            break;

            //Only Letters, numbers, white space, special char (ç) and comma
            case 3:
                this.isValidName = /^[A-Z-a-z-À-ü( )\,\ç\s]+$/gm.test(value);
            break;

            //Only Letters, numbers, white space and special chars
            case 4:
                this.isValidName = /^[0-9-A-Z-a-z-À-ü( )/\-\.\º\"\'\:\,\ç\&\s\!\?]+$/gm.test(value);
            break;

            //Only Letters, Numbers and White Space
            case 5:
                this.isValidName = /^[0-9-A-Z-a-z( )]+$/gm.test(value);
            break;

            //Only Letters, numbers, white space, special chars and html tags
            case 6:
                this.isValidName = /^[0-9-A-Z-a-z-À-ü( )/\-\.\º\"\'\:\,\ç\&\s\<\>\</\!\_]+$/gm.test(value);
            break;

            //Only Numbers and no WhiteSpaces
            case 7:
                this.isValidName = /^[0-9()\-\.]+$/gm.test(value);
            break;

            //Only Letters, numbers, white space and special char (ç)
            default:
                //Check Element
                this.isValidName = /^[0-9-A-Z-a-z-À-ü( )\ç\s]+$/gm.test(value);
            break;

        }

        return this.isValidName;

    }

    onlyLettersAndSpacesAndNumbers(e, inputid, type){

        //Get Element
        let element = e.currentTarget;

        //Get Span Error Message Element
        let errorElement = element.nextElementSibling;

        //Get Value From Input
        let value = element.value;

        //Verify Value
        if(value){

            //Show Message Error
            if(!this.validNameCases(type, value) && value){

                //Add Class for Error
                element.classList.add('inputerror');
                element.classList.add('input0form-1');
                this.disabled = true;

                //InputName Verify
                this.errorMessageCondtional.inputdisplay.generateerror = true;

                //Conditional for generate the error message
                if(this.errorMessageCondtional.inputdisplay.generateerror){

                    //Show Error Message
                    if(!this.errorMessageCondtional.inputdisplay.errormessageexist){

                        this.createErrorMessageElement(inputid, 'Don\'t use special characters');

                    }

                    //Verify Error Message Exist
                    this.errorMessageCondtional.inputdisplay.errormessageexist = true;

                    //Disabled BTN
                    this.disabled = true;

                }

            }else{

                //When the value is more then 150 caracters
                if(value.length >= 150){

                    //Add Class for Error
                    element.classList.add('inputerror');
                    element.classList.add('input0form-1');
                    this.disabled = true;

                    this.createErrorMessageElement(inputid, 'Maximum 150 characters');

                    //Disabled BTN
                    this.disabled = true;

                    return this.disabled;

                }

                //InputName Verify
                this.errorMessageCondtional.inputdisplay.generateerror = false;

                //Verify Error Message Exist
                this.errorMessageCondtional.inputdisplay.errormessageexist = false;

                //Remove Message
                if(errorElement){
                    //Remove Class
                    if(element.classList.contains('inputerror')){

                        element.classList.remove('inputerror');

                    }
                    //Remove element span
                    if(errorElement.classList.contains('errorMessage') && !this.errorMessageCondtional.inputdisplay.generateerror){

                        errorElement.remove();
                    }

                }

                //Enabled BTN
                this.disabled = false;

            }

        }else{

            //InputName Verify
            this.errorMessageCondtional.inputdisplay.generateerror = false;

            //Verify Error Message Exist
            this.errorMessageCondtional.inputdisplay.errormessageexist = false;

            //Remove Error Message
            if(errorElement){

                //Remove Class
                if(element.classList.contains('inputerror')){

                    element.classList.remove('inputerror');

                }

                //Remove element span
                if(errorElement.classList.contains('errorMessage')){
                    errorElement.remove();

                }

            }

        }

        return this.disabled;

    }

    createErrorMessageElement(idname, errorMessage){

        //Check when span exist
        if(!document.querySelector('span.errorMessage')){
            
            //Get Element to Apeend
            let input = document.getElementById(idname);

            //Create Element
            let spanError = document.createElement('span');
    
            //Set Attribute for the element
            spanError.setAttribute('class', 'errorMessage order-3');
            spanError.innerText = errorMessage;

            //Create Element After Input
            input.after(spanError);

            return;

        }

        return;

    }

    isValidUrl(string, idname, showmessage = false) {

        try {

          //Validate the url
          new URL(string);

          //Generate Error
          if(showmessage){
            this.createErrorMessageElement(idname, 'Please inform the correct Url format');
          }
          return true;

        } catch (err) {

          return false;

        }

    }

}

export default validation;
